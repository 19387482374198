import React from 'react';
import LogoImage from "../../../assets/svg/Frame 37912.svg";
import SidebarImage from "../../../assets/svg/Register image.svg";
import {Box} from "@mui/material";
// import SidebarImage from "./../../../assets/png/Register image (1).png";
function CommonRightSidebar(props) {
    return (
        <>
            <Box flex={"1 1"} sx={{
                position: "relative",
                display: "flex",
                background: "#F6FAFF",
                paddingX: "5px",
                justifyContent: "center",
                alignItems: "center"
            }}>
                <img style={{position: "absolute", width: "130px", left: "15px", top: "15px", zIndex: 12}}
                     src={LogoImage}/>
                {/*<img style={{position: "absolute", left: 0, top: 0, width: "100%", height: "100vh"}}*/}
                {/*     src={SidebarImage}/>*/}
                {/*<Box sx={{*/}
                {/*    width: "50%",*/}
                {/*    height: "100vh",*/}
                {/*    background: "rgba(245,245,245,.1)",*/}
                {/*    transform: " rotate(-15deg) translate(0, 25%)",*/}
                {/*    backdropFilter: "blur(5px)"*/}
                {/*}}>*/}
                <Box sx={{position: "absolute", left: 0, top: 0, width: "100%", height: "100vh",backgroundImage:`url('${SidebarImage}')`,backgroundSize:"contain",backgroundRepeat:"no-repeat",backgroundPosition:"center"}} />

                {/*</Box>*/}
            </Box>
        </>
    );
}

export default CommonRightSidebar;