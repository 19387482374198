import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Link, Navigate, Route, Routes, useNavigate} from "react-router-dom"
import Registration from "./routes/authorization/registration"
import Login from "./routes/authorization/login"
import {useLocation} from "react-router-dom"
import AccountCreatedSuccess from "./routes/authorization/registration/account-created-success";
import Admin from "./routes/admin/index"
import {Box, Container, CssBaseline, Toolbar, Typography} from "@mui/material";
import MenuSidebar from "./routes/components/layout-component/sidebar/menu-sidebar";
import 'react-toastify/dist/ReactToastify.css';
import MainFooter from "./routes/components/layout-component/main-footer";
import AboutApi from "./apis/authorization/login-api"
import {updateToken} from "./slice/UserSlice";
import MuiXLicense from "./routes/components/layout-component/mui-licence";
import PrivateRoutes from "./routes/components/authorization-component/private-routes";
import ForgotPassword from "./routes/authorization/forgot-password";
import {ToastContainer} from "react-toastify";

function AdminRoutes() {
    return <>
        {/*<Routes>*/}
        <Admin/>
        {/*<Route path={"/admin/*"} element={<Admin/>}/>*/}
        {/*</Routes>*/}
    </>
}

function SecurePath(props) {
    const {authUser} = props
    const dispatch=useDispatch()
    useEffect(() => {
        let fetchAccessTokenIntervel=setInterval(()=>{
            AboutApi.getAccessToken({refreshToken:authUser.refreshToken},null).then((res)=>{
                dispatch(updateToken({token:res.data.token}))
            }).catch((e)=>{

            })
        },30000)
        return () => {
            clearInterval(fetchAccessTokenIntervel)
        }
    }, [])
    return (
        <>
            <CssBaseline/>

            <Box sx={{display: 'flex', background: "#F5F6FA"}}>
                <MenuSidebar/>
                <Box component="main" sx={{flexGrow: 1, p: 0, minHeight: "100%",overflow:"hidden"}}>
                    <Toolbar
                        sx={{height: "55px !important", minHeight: "55px !important", maxHeight: "55px !important"}}/>
                    <Box sx={{height: "calc(100vh - 55px)", display: "flex", flexFlow: "column", overflow: "auto"}}>
                        <Box sx={{flex: "1 1", overflow: "auto"}}>
                            <Box sx={{width:"100%",height:"100%",background:"blue",display:"none"}} className={"myGrid"}>
                             <AdminRoutes/>
                            </Box>
                        </Box>
                        <Box sx={{flex: "fit-content 0"}}>
                            <MainFooter/>
                        </Box>
                    </Box>

                </Box>
            </Box>

        </>
    )
}


function MainApp(props) {
    const authUser = useSelector((state) => (state.authUser))
    // const [isLogin, setLogin] = useState(false)
    // const navigate = useNavigate()
    // const location = useLocation()
    // useEffect(() => {
    //     if(!location.pathname.startsWith("/registration") && !location.pathname.startsWith("/forget-password")){
    //         if (authUser.refreshToken === null ) {
    //             setLogin(false)
    //             return navigate("/login")
    //         }else{
    //                 setLogin(true)
    //                 navigate("/app/about-companies")
    //         }
    //     }
    //     // navigate("/app/installation")
    // }, [authUser.refreshToken])
    return (
        <>
            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            <MuiXLicense />
            <Routes>
                <Route path={"/registration/*"} element={<Registration/>}/>
                <Route path={"/forgot-password/*"} element={<ForgotPassword/>}/>
                <Route path={"/login"} element={authUser.isAuthenticated?<Navigate to={"/app/about-companies"}/>:<Login/>}/>
                {/*<Route path={"/forget-password"} element={<Registration/>}/>*/}
                {/*{isLogin && <Route path={"/app/*"} element={<SecurePath authUser={authUser}/>}/>}*/}
                <Route path={"/*"} element={<PrivateRoutes/>} />
            </Routes>
        </>
    );
}

export default MainApp;