import AboutIcon from "../../../assets/svg-icon-for-menu/about-icon.svg";
import ManageCompanyIcon from "../../../assets/svg-icon-for-menu/managed-companies-icon.svg";
import TeamMembersIcon from "../../../assets/svg-icon-for-menu/team-members-icon.svg";
import AlertsIcon from "../../../assets/svg-icon-for-menu/alerts-icon.svg";
import ActivityLogIcon from "../../../assets/svg-icon-for-menu/activity-log-icon.svg";
import SystemSettingIcon from "../../../assets/svg-icon-for-menu/system-settings-icon.svg";
import TerritoriesIcon from "../../../assets/svg-icon-for-menu/territories-icon.svg";
import StatutoryHolidaysIcon from "../../../assets/svg-icon-for-menu/statutory-holidays-icon.svg";
import TimeOfUsePlansIcon from "../../../assets/svg-icon-for-menu/time-of-use-plans-icon.svg";
import SaleTaxPlansIcon from "../../../assets/svg-icon-for-menu/sales-tax-plans-icon.svg";
import InstallationIcon from "../../../assets/svg-icon-for-menu/installation-icon.svg";
import GeneralInformationIcon from "../../../assets/svg-icon-for-menu/general-information-icon.svg";
import ChargingStatusIcon from "../../../assets/svg-icon-for-menu/charging-status-icon.svg";
import TransactionsIcon from "../../../assets/svg-icon-for-menu/transactions-icon.svg";
import BillingSettingsIcon from "../../../assets/svg-icon-for-menu/billing-icon-settings.svg";
import ControllersIcon from "../../../assets/svg-icon-for-menu/controllers-icon.svg";
import ChargingPointsIcons from "../../../assets/svg-icon-for-menu/charge-points-icon.svg";
import LicencesIcon from "../../../assets/svg-icon-for-menu/licences-icon.svg";
import DriversIcon from "../../../assets/svg-icon-for-menu/drivers-icon.svg";

import AboutWhiteIcon from "../../../assets/svg-icon-for-menu/white-icon/about-white-icon.svg"
import ManagedCompanyWhiteIcon from "../../../assets/svg-icon-for-menu/white-icon/managed-company-white-icon.svg"
import TeamMemberWhiteIcon from "../../../assets/svg-icon-for-menu/white-icon/team-member-white-icon.svg"
import AlertsWhiteIcon from "../../../assets/svg-icon-for-menu/white-icon/alerts-white-icon.svg"
import ActivityLogWhiteIcon from "../../../assets/svg-icon-for-menu/white-icon/activity-log-white-icon.svg"
import SystemSettingWhiteIcon from "../../../assets/svg-icon-for-menu/white-icon/system-setting-white-icon.svg"
import TerritoriesWhiteIcon from "../../../assets/svg-icon-for-menu/white-icon/territories-white-icon.svg"
import StatutoryHolidaysWhiteIcon from "../../../assets/svg-icon-for-menu/white-icon/statutory-holidays-white-icon.svg"
import TimeOfUsePlansWhiteIcon from "../../../assets/svg-icon-for-menu/white-icon/time-of-use-plans-white-icon.svg"
import SalesTaxPlanWhiteIcon from "../../../assets/svg-icon-for-menu/white-icon/sales-tax-plans-white-icon.svg"
import InstallationWhiteIcon from "../../../assets/svg-icon-for-menu/white-icon/installation-white-icon.svg"
import GeneralInfoWhiteIcon from "../../../assets/svg-icon-for-menu/white-icon/general-information-white-icon.svg"
import DriversWhiteIcon from "../../../assets/svg-icon-for-menu/white-icon/drivers-white-icon.svg"
import TransactionsWhiteIcon from "../../../assets/svg-icon-for-menu/white-icon/transactions-white-icon.svg"
import BillingSettingWhiteIcon from "../../../assets/svg-icon-for-menu/white-icon/billing-setting-white-icon.svg"
import ControllerWhiteIcon from "../../../assets/svg-icon-for-menu/white-icon/controller-white-icon.svg"
import ChargepointWhiteIcon from "../../../assets/svg-icon-for-menu/white-icon/charge-points-white-icon.svg"
import LicencesWhiteIcon from "../../../assets/svg-icon-for-menu/white-icon/licences-white-icon.svg"
import {Avatar} from "@mui/material";
import React, {useEffect} from "react";


export const PATH_LIST = {
    ABOUT_COMPANIES: "/app/about-companies",
    MANAGED_COMPANIES: "/app/managed-companies",
    TEAM_MEMBERS: "/app/team-members",
    ALERTS: "/app/alerts",
    ACTIVITY_LOG: "/app/activity-log",
    SYSTEM_SETTING_TERRITORIES: "/app/system-settings/territories",
    SYSTEM_SETTING_HOLIDAY: "/app/system-settings/statutory-holidays",
    SYSTEM_SETTING_TIME_OF_USE_PLANS: "/app/system-settings/time-of-use-plans",
    SYSTEM_SETTING_SALES_TAB_PLANS: "/app/system-settings/sales-tax-plans",
    INSTALLATION: "/app/installation",
    INSTALLATION_GENERAL_INFO: "/app/installation/general-info",
    INSTALLATION_CHARGING_STATUS: "/app/installation/charging-status",
    INSTALLATION_DRIVER: "/app/installation/drivers",
    INSTALLATION_TRANSACTIONS: "/app/installation/transactions",
    INSTALLATION_BILLING_SETTINGS: "/app/installation/billing-settings",
    INSTALLATION_CONTROLLERS: "/app/installation/controllers",
    INSTALLATION_CHARGE_POINTS: "/app/installation/chargepoints",
    INSTALLATION_LICENCES: "/app/installation/licences",


}

export const MENU_ID_LIST = {
    "about": [PATH_LIST.ABOUT_COMPANIES],
    "managed companies": [PATH_LIST.MANAGED_COMPANIES],
    "Team members": [PATH_LIST.TEAM_MEMBERS],
    "alerts": [PATH_LIST.ALERTS],
    "Activity log": [PATH_LIST.ACTIVITY_LOG],
    "System Settings": [PATH_LIST.SYSTEM_SETTING_TERRITORIES, PATH_LIST.SYSTEM_SETTING_HOLIDAY, PATH_LIST.SYSTEM_SETTING_TIME_OF_USE_PLANS, PATH_LIST.SYSTEM_SETTING_SALES_TAB_PLANS],
    "Statutory holidays": [PATH_LIST.SYSTEM_SETTING_HOLIDAY],
    "Territories": [PATH_LIST.SYSTEM_SETTING_TERRITORIES],
    "Time-of-use plans": [PATH_LIST.SYSTEM_SETTING_TIME_OF_USE_PLANS],
    "Sales tax plans": [PATH_LIST.SYSTEM_SETTING_SALES_TAB_PLANS],
    "Installation": [PATH_LIST.INSTALLATION, PATH_LIST.INSTALLATION_GENERAL_INFO, PATH_LIST.INSTALLATION_CHARGING_STATUS, PATH_LIST.INSTALLATION_TRANSACTIONS, PATH_LIST.INSTALLATION_DRIVER, PATH_LIST.INSTALLATION_BILLING_SETTINGS, PATH_LIST.INSTALLATION_CONTROLLERS, PATH_LIST.INSTALLATION_CHARGE_POINTS, PATH_LIST.INSTALLATION_LICENCES],
    "Settings": [PATH_LIST.INSTALLATION_GENERAL_INFO],
    "Charging status": [PATH_LIST.INSTALLATION_CHARGING_STATUS],
    "Drivers": [PATH_LIST.INSTALLATION_DRIVER],
    "Transactions": [PATH_LIST.INSTALLATION_TRANSACTIONS],
    "Billing settings": [PATH_LIST.INSTALLATION_BILLING_SETTINGS],
    "Controllers": [PATH_LIST.INSTALLATION_CONTROLLERS],
    "Chargepoints": [PATH_LIST.INSTALLATION_CHARGE_POINTS],
    "Licences": [PATH_LIST.INSTALLATION_LICENCES]
}

function MenuIconAvatar({icon}) {
    useEffect(() => {
        const img = new Image();
        img.src = icon;
    }, [icon]);
    return (<Avatar
        variant={"square"}
        src={icon} sx={{
        width: 20,
        height: 20,
        bgcolor: "transparent",
        fill: "white"
    }}/>)
}

// case "V":
// return "Variablegrid"
// case "R":
// return "Customer"
// case "C":
// return "Contractor"
// case "G":
// return "Group"
// case "D":
// return "Dealer"

let SYSTEM_SETTING_MENU={
        name: "System Settings",
        icon: <MenuIconAvatar icon={SystemSettingIcon}/>,
        whiteIcon: <MenuIconAvatar icon={SystemSettingWhiteIcon}/>,
        id: "System Settings",
        child: [
            {
                id: "Territories",
                name: "Territories",
                icon: <MenuIconAvatar icon={TerritoriesIcon}/>,
                whiteIcon: <MenuIconAvatar icon={TerritoriesWhiteIcon}/>,
                path: PATH_LIST.SYSTEM_SETTING_TERRITORIES
            }, {
                id: "Statutory holidays",
                name: "Statutory holidays",
                icon: <MenuIconAvatar icon={StatutoryHolidaysIcon}/>,
                whiteIcon: <MenuIconAvatar icon={StatutoryHolidaysWhiteIcon}/>,
                path: PATH_LIST.SYSTEM_SETTING_HOLIDAY
            },
            {
                id: "Time-of-use plans",
                name: "Time-of-use plans",
                icon: <MenuIconAvatar icon={TimeOfUsePlansIcon}/>,
                whiteIcon: <MenuIconAvatar icon={TimeOfUsePlansWhiteIcon}/>,
                path: PATH_LIST.SYSTEM_SETTING_TIME_OF_USE_PLANS
            },
            {
                id: "Sales tax plans",
                name: "Sales tax plans",
                icon: <MenuIconAvatar icon={SaleTaxPlansIcon}/>,
                whiteIcon: <MenuIconAvatar icon={SalesTaxPlanWhiteIcon}/>,
                path: PATH_LIST.SYSTEM_SETTING_SALES_TAB_PLANS
            }

        ],
    }
export const allMenuList = {
    //varable grid
    "V": [
        {
            name: "About",
            id: "about",
            icon: <MenuIconAvatar icon={AboutIcon}/>,
            path: PATH_LIST.ABOUT_COMPANIES,
            whiteIcon: <MenuIconAvatar icon={AboutWhiteIcon}/>
        },
        {
            name: "Managed companies",
            id: "managed companies",
            icon: <MenuIconAvatar icon={ManageCompanyIcon}/>,
            whiteIcon: <MenuIconAvatar icon={ManagedCompanyWhiteIcon}/>,
            path: PATH_LIST.MANAGED_COMPANIES
        },
        {
            name: "Team members",
            id: "Team members",
            icon: <MenuIconAvatar icon={TeamMembersIcon}/>,
            whiteIcon: <MenuIconAvatar icon={TeamMemberWhiteIcon}/>,
            path: PATH_LIST.TEAM_MEMBERS
        },
        {
            name: "Alerts",
            id: "alerts",
            icon: <MenuIconAvatar icon={AlertsIcon}/>,
            whiteIcon: <MenuIconAvatar icon={AlertsWhiteIcon}/>,
            path: PATH_LIST.ALERTS,
            haveBadge: false,
        },
        {
            name: "Activity log",
            id: "Activity log",
            icon: <MenuIconAvatar icon={ActivityLogIcon}/>,
            whiteIcon: <MenuIconAvatar icon={ActivityLogWhiteIcon}/>,
            path: PATH_LIST.ACTIVITY_LOG
        },

    ],
    //customer
    "R": [
        {
            name: "About",
            id: "about",
            icon: <MenuIconAvatar icon={AboutIcon}/>,
            path: PATH_LIST.ABOUT_COMPANIES,
            whiteIcon: <MenuIconAvatar icon={AboutWhiteIcon}/>
        },
        // {name: "Managed companies", id: "managed companies", icon: <MenuIconAvatar icon={ManageCompanyIcon}/,whiteIcon:<MenuIconAvatar icon={ ManagedCompanyWhiteIcon} /, path:PATH_LIST.MANAGED_COMPANIES},
        {
            name: "Team members",
            id: "Team members",
            icon: <MenuIconAvatar icon={TeamMembersIcon}/>,
            whiteIcon: <MenuIconAvatar icon={TeamMemberWhiteIcon}/>,
            path: PATH_LIST.TEAM_MEMBERS
        },
        {
            name: "Alerts",
            id: "alerts",
            icon: <MenuIconAvatar icon={AlertsIcon}/>,
            whiteIcon: <MenuIconAvatar icon={AlertsWhiteIcon}/>,
            path: PATH_LIST.ALERTS,
            haveBadge: false
        },
        {
            name: "Activity log",
            id: "Activity log",
            icon: <MenuIconAvatar icon={ActivityLogIcon}/>,
            whiteIcon: <MenuIconAvatar icon={ActivityLogWhiteIcon}/>,
            path: PATH_LIST.ACTIVITY_LOG
        }
    ],
    //customer support
    "C": [
        {
            name: "About",
            id: "about",
            icon: <MenuIconAvatar icon={AboutIcon}/>,
            path: PATH_LIST.ABOUT_COMPANIES,
            whiteIcon: <MenuIconAvatar icon={AboutWhiteIcon}/>
        },
        {
            name: "Managed companies",
            id: "managed companies",
            icon: <MenuIconAvatar icon={ManageCompanyIcon}/>,
            whiteIcon: <MenuIconAvatar icon={ManagedCompanyWhiteIcon}/>,
            path: PATH_LIST.MANAGED_COMPANIES
        },
        {
            name: "Team members",
            id: "Team members",
            icon: <MenuIconAvatar icon={TeamMembersIcon}/>,
            whiteIcon: <MenuIconAvatar icon={TeamMemberWhiteIcon}/>,
            path: PATH_LIST.TEAM_MEMBERS
        },
        {
            name: "Alerts",
            id: "alerts",
            icon: <MenuIconAvatar icon={AlertsIcon}/>,
            whiteIcon: <MenuIconAvatar icon={AlertsWhiteIcon}/>,
            path: PATH_LIST.ALERTS,
            haveBadge: false
        },
        {
            name: "Activity log",
            id: "Activity log",
            icon: <MenuIconAvatar icon={ActivityLogIcon}/>,
            whiteIcon: <MenuIconAvatar icon={ActivityLogWhiteIcon}/>,
            path: PATH_LIST.ACTIVITY_LOG
        }
    ],
    //group
    "G": [
        {
            name: "About",
            id: "about",
            icon: <MenuIconAvatar icon={AboutIcon}/>,
            path: PATH_LIST.ABOUT_COMPANIES,
            whiteIcon: <MenuIconAvatar icon={AboutWhiteIcon}/>
        },
        {
            name: "Managed companies",
            id: "managed companies",
            icon: <MenuIconAvatar icon={ManageCompanyIcon}/>,
            whiteIcon: <MenuIconAvatar icon={ManagedCompanyWhiteIcon}/>,
            path: PATH_LIST.MANAGED_COMPANIES
        },
        {
            name: "Team members",
            id: "Team members",
            icon: <MenuIconAvatar icon={TeamMembersIcon}/>,
            whiteIcon: <MenuIconAvatar icon={TeamMemberWhiteIcon}/>,
            path: PATH_LIST.TEAM_MEMBERS
        },
        {
            name: "Alerts",
            id: "alerts",
            icon: <MenuIconAvatar icon={AlertsIcon}/>,
            whiteIcon: <MenuIconAvatar icon={AlertsWhiteIcon}/>,
            path: PATH_LIST.ALERTS,
            haveBadge: false
        },
        {
            name: "Activity log",
            id: "Activity log",
            icon: <MenuIconAvatar icon={ActivityLogIcon}/>,
            whiteIcon: <MenuIconAvatar icon={ActivityLogWhiteIcon}/>,
            path: PATH_LIST.ACTIVITY_LOG
        }
    ],
    //dealer
    "D": [
        {
            name: "About",
            id: "about",
            icon: <MenuIconAvatar icon={AboutIcon}/>,
            path: PATH_LIST.ABOUT_COMPANIES,
            whiteIcon: <MenuIconAvatar icon={AboutWhiteIcon}/>
        },
        {
            name: "Managed companies",
            id: "managed companies",
            icon: <MenuIconAvatar icon={ManageCompanyIcon}/>,
            whiteIcon: <MenuIconAvatar icon={ManagedCompanyWhiteIcon}/>,
            path: PATH_LIST.MANAGED_COMPANIES
        },
        {
            name: "Team members",
            id: "Team members",
            icon: <MenuIconAvatar icon={TeamMembersIcon}/>,
            whiteIcon: <MenuIconAvatar icon={TeamMemberWhiteIcon}/>,
            path: PATH_LIST.TEAM_MEMBERS
        },
        {
            name: "Alerts",
            id: "alerts",
            icon: <MenuIconAvatar icon={AlertsIcon}/>,
            whiteIcon: <MenuIconAvatar icon={AlertsWhiteIcon}/>,
            path: PATH_LIST.ALERTS,
            haveBadge: false
        },
        {
            name: "Activity log",
            id: "Activity log",
            icon: <MenuIconAvatar icon={ActivityLogIcon}/>,
            whiteIcon: <MenuIconAvatar icon={ActivityLogWhiteIcon}/>,
            path: PATH_LIST.ACTIVITY_LOG
        }
    ],
    //superviser
    "S": [
        {
            name: "About",
            id: "about",
            icon: <MenuIconAvatar icon={AboutIcon}/>,
            path: PATH_LIST.ABOUT_COMPANIES,
            whiteIcon: <MenuIconAvatar icon={AboutWhiteIcon}/>
        },
        {
            name: "Managed companies",
            id: "managed companies",
            icon: <MenuIconAvatar icon={ManageCompanyIcon}/>,
            whiteIcon: <MenuIconAvatar icon={ManagedCompanyWhiteIcon}/>,
            path: PATH_LIST.MANAGED_COMPANIES
        },
        {
            name: "Team members",
            id: "Team members",
            icon: <MenuIconAvatar icon={TeamMembersIcon}/>,
            whiteIcon: <MenuIconAvatar icon={TeamMemberWhiteIcon}/>,
            path: PATH_LIST.TEAM_MEMBERS
        },
        {
            name: "Alerts",
            id: "alerts",
            icon: <MenuIconAvatar icon={AlertsIcon}/>,
            whiteIcon: <MenuIconAvatar icon={AlertsWhiteIcon}/>,
            path: PATH_LIST.ALERTS,
            haveBadge: false
        },
        {
            name: "Activity log",
            id: "Activity log",
            icon: <MenuIconAvatar icon={ActivityLogIcon}/>,
            whiteIcon: <MenuIconAvatar icon={ActivityLogWhiteIcon}/>,
            path: PATH_LIST.ACTIVITY_LOG
        }


    ],
}

export const InstallationMenuList = {
    name: "Installation",

    icon: <MenuIconAvatar icon={InstallationIcon}/>,
    whiteIcon: <MenuIconAvatar icon={InstallationWhiteIcon}/>,

    id: "Installation",
    child: [
        {
            id: "Settings",
            name: "Settings",
            icon: <MenuIconAvatar icon={SystemSettingIcon}/>,
            whiteIcon:<MenuIconAvatar icon={SystemSettingWhiteIcon}/>,
            path: PATH_LIST.INSTALLATION_GENERAL_INFO
        },
        // {
        //     id: "Charging status",
        //     name: "Charging status",
        //     icon: ChargingStatusIcon,
        //     path: PATH_LIST.INSTALLATION_CHARGING_STATUS
        // },
        {
            id: "Drivers",
            name: "Drivers",
            icon: <MenuIconAvatar icon={DriversIcon}/>,
            whiteIcon: <MenuIconAvatar icon={DriversWhiteIcon}/>,
            path: PATH_LIST.INSTALLATION_DRIVER
        },
        {
            id: "Transactions",
            name: "Transactions",
            icon: <MenuIconAvatar icon={TransactionsIcon}/>,
            whiteIcon: <MenuIconAvatar icon={TransactionsWhiteIcon}/>,
            path: PATH_LIST.INSTALLATION_TRANSACTIONS
        },

        {
            id: "Billing settings",
            name: "Billing settings",
            icon: <MenuIconAvatar icon={BillingSettingsIcon}/>,
            whiteIcon: <MenuIconAvatar icon={BillingSettingWhiteIcon}/>,
            path: PATH_LIST.INSTALLATION_BILLING_SETTINGS
        },
        {
            id: "Controllers",
            name: "Controllers",
            icon: <MenuIconAvatar icon={ControllersIcon}/>,
            whiteIcon: <MenuIconAvatar icon={ControllerWhiteIcon}/>,
            path: PATH_LIST.INSTALLATION_CONTROLLERS
        },
        {
            id: "Chargepoints",
            name: "Chargepoints",
            icon: <MenuIconAvatar icon={ChargingPointsIcons}/>,
            whiteIcon: <MenuIconAvatar icon={ChargepointWhiteIcon}/>,
            path: PATH_LIST.INSTALLATION_CHARGE_POINTS
        },
        {
            id: "Licences",
            name: "Licences",
            icon: <MenuIconAvatar icon={LicencesIcon}/>,
            whiteIcon: <MenuIconAvatar icon={LicencesWhiteIcon}/>,
            path: PATH_LIST.INSTALLATION_LICENCES
        },

    ],
}
export const WithoutInstallationMenu = {
    name: "Installation",
    icon: <MenuIconAvatar icon={SystemSettingIcon}/>,
    // whiteIcon: <MenuIconAvatar icon={InstallationWhiteIcon}/>,
    whiteIcon: <MenuIconAvatar icon={SystemSettingWhiteIcon}/>,
    id: "Installation", path: "/app/installation"
}
export const manageCompanyMenu = {
    name: "Managed companies",
    id: "managed companies",
    icon: <MenuIconAvatar icon={ManageCompanyIcon}/>,
    whiteIcon: <MenuIconAvatar icon={ManagedCompanyWhiteIcon}/>,
    path: PATH_LIST.MANAGED_COMPANIES
}




