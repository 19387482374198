import {toast} from "react-toastify";
import _ from "lodash";

export function ErrorToast(e){
    try{
        let error=e?.errors[0]?.displayMessage || ""
        toast.error(error)    
    }catch (err) {

        try{

            toast.error(e.message ||e?.msg)
        }catch (e) {
            toast.error(e?.msg || "Something went wrong")
        }
    }
    
}

export const REQUIRED_MESSAGE='Required'

export function changedFieldsGetter(obj1, obj2) {
    const diffObj = _.omitBy(obj1, (value, key) => _.isEqual(value, obj2[key]));
    return diffObj;
}
