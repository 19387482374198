import React from 'react';
import IdleTimer from 'react-idle-timer';
import {logoutUser} from "../../../slice/UserSlice";
import {useDispatch} from "react-redux";
function UserIdleTimer(props) {

    const dispatch = useDispatch()
    const onIdle = () => {

        dispatch(logoutUser())
    };

    const onActive = () => {

    };



    return (
        <>

            <IdleTimer
                timeout={900000} // Set the idle timeout to 15 min in milliseconds
                // timeout={60000} // Set the idle timeout to 1 min in milliseconds
                onIdle={onIdle}
                onActive={onActive}
                startOnMount={true}
                debounce={250}
            />


        </>
    );

}

export default UserIdleTimer;