export function setToken(token) {
    localStorage.setItem("token", token)
}

export function getToken() {
    return localStorage.getItem("token")
}

export function setUserOnCache(user) {
    localStorage.setItem("__user__", btoa(user))
}

export function removeUserToCache(user) {
    localStorage.removeItem("__user__")
}

export function getUserToCache() {
    let user = localStorage.getItem("__user__")
    if (user) {
        try {
            return JSON.parse(atob(user))

        } catch (e) {
            return null
        }
    }
    return null
}

export function getUserRefreshTokenToCache() {
    let user = localStorage.getItem("__user__")
    if (user) {
        user = JSON.parse(atob(user))
        return user.refreshToken
    }
    return null
}