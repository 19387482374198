import React, {useState} from 'react';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import {IconButton} from "@mui/material";

const SearchFilter = ({ onSearch,...p }) => {
    const [value,setValue]=useState("")
    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            if(onSearch){
                onSearch(event.target.value);
            }
        }
    };
    const handleValueChange=(value)=>{
        setValue(value)
    }
    const onClearValue=()=>{
        setValue("")
        if(onSearch){
            onSearch("");
        }
    }
    let endAdornment= {
    }

    if(value.trim() !==""){
        endAdornment= {
            endAdornment: (
                <IconButton onClick={onClearValue}><ClearIcon style={{color: "grey"}}/></IconButton>
            )
        }
    }
    return (
        <TextField
            variant="outlined"
            placeholder="Search"
            fullWidth={true}
            onKeyPress={handleKeyPress}
            onChange={(e)=>handleValueChange(e.target.value)}
            InputProps={{
                startAdornment: (
                    <SearchIcon style={{ marginRight: 8, color: 'grey' }} />
                ),
                ...endAdornment
            }}
            {...p}
            value={value}
        />
    );
};

export default SearchFilter;
