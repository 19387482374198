import React, {useEffect, useMemo, useRef, useState} from 'react';
import {Avatar, Box, Button, Checkbox, CircularProgress, TextField, Typography, useTheme} from "@mui/material";
import LoginIcon from "../../../assets/svg/Login.svg";
import CommonRightSidebar from "../components/common-right-sidebar";
import {Link} from "react-router-dom";
import PasswordInputField from "./components/password-input-field";
import {isEmailValid} from "../../../helper/helper";
import LoginApi from "../../../apis/authorization/login-api"
import {loginUser} from "../../../slice/UserSlice"
//
import StyledLinkComponent from "../../../components/styled-link-component"
import {useDispatch} from "react-redux";
import LoadingButton from "../../components/button-component/loading-button";
import {setToken} from "../../../helper/cache-helper";
import {ErrorToast} from "../../../Util/util";
import {getUserCredentials, removeUserCredentials, setUserCredentials} from "./helper/cache-helper";



function Index(props) {

    const userCredentials=useMemo(()=>(getUserCredentials()),[])
    const [state, setState] = useState({loading: false,formData:{email:userCredentials?.userName,password:userCredentials?.password,rememberMe:userCredentials.isRemember}, error: {username: false, password: false}})
    const {error, loading,formData} = state
    const dispatch = useDispatch()
    function validateForm(){
        let validEmail=isEmailValid(formData.email)
        let validPassword=formData.password.trim().length ===0
        setState(prevState => ({...prevState,error: {...prevState.error,username: validEmail,password: validPassword}}))
        return (validEmail && !validPassword)
    }
    function onSubmit() {

        if(validateForm()){
            setState(prevState => ({...prevState,error: {username: false,password: false}, loading: true}))
            let data={"userName":formData.email,"password":formData.password}
            LoginApi.loginApi(data,"").then((res)=>{
                if(formData.rememberMe){
                    setToken(res.data.refreshToken)
                }
                dispatch(loginUser(res.data))
                setState(prevState => ({
                    ...prevState,
                    loading: false,
                }))
            }).catch((e)=>{
                try{
                    let error={username: false,password: false}
                    if(e?.errors[0]?.errorMessage==="Invalid parameter:Invalid Credentials."){
                        error={username: true,password: true}
                    }else{
                        ErrorToast(e)
                    }
                    setState(prevState => ({
                        ...prevState,
                        loading: false,
                        error
                    }))
                }catch (err){
                    let error={username: false,password: false}
                    setState(prevState => ({
                        ...prevState,
                        loading: false,
                        error
                    }))
                    // console.log(e.code)
                    // alert(e.code)
                }

            })


        }else{
            setState(prevState => ({...prevState,loading: false,error: {...prevState.error,username: true}}))
        }
    }
    // const [parentSize, setParentSize] = useState(0);
    // const parentRef = useRef(null);

    // useEffect(() => {
    //     const { clientHeight, clientWidth } = parentRef.current;
    //
    //     setParentSize(Math.min(clientHeight, clientWidth));
    // }, []);


    function onFormDataChange(field,value) {
        console.log(field,value,"ViewData>>>")


        setState(prevState => ({...prevState,formData: {...prevState.formData,[field]:value}}))
    }
    useEffect(()=>{
        if(state.formData.rememberMe ){
            setUserCredentials(formData.email,formData.password)
        }
        if(!state.formData.rememberMe ){
            removeUserCredentials()
        }
    },[formData])
    function handleSubmit(e){
        e.preventDefault()
        // alert("y")
    }

    return (
        <>
            <Box sx={{display: "flex", flexFlow: "row", height: "100vh"}}>
                <Box sx={{display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column"}}
                     flex={"1 1"}>
                    <form onSubmit={handleSubmit} style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', gap: '15px', width: '60%' }} flex={'1 1'}>
                    {/*<Box sx={{display: "flex", justifyContent: "center", flexDirection: "column", gap: "15px",width:"60%"}}*/}
                    {/*     flex={"1 1"}>*/}
                        <Box flex={"fit-content 0"} display={"flex"} gap={"5px"} alignItems={"center"}>
                            <Avatar src={LoginIcon} variant="square"/>
                            <Typography variant={"h5"} >Log into your account</Typography>
                        </Box>
                        <Box flex={"fit-content 0"} display={"flex"}  flexDirection={"column"} gap={"5px"}>
                            <Typography variant={"body2"}>Email</Typography>
                            <TextField autoComplete={'off'} inputProps={{autoComplete:"new-password"}} onChange={(e)=>onFormDataChange("email",e.target.value)} error={error.username} value={formData.email} helperText={error.username?"User with this email doesn't exists":""} placeholder={"example@gmail.com"}/>
                        </Box>
                        <Box flex={"fit-content 0"} display={"flex"} flexDirection={"column"} gap={"5px"}>
                            <Typography variant={"body2"}>Password</Typography>
                            <PasswordInputField autoComplete={'new-password'} onChange={(e)=>onFormDataChange("password",e.target.value)} value={formData.password} error={error.password}/>
                        </Box>
                        <Box flex={"fit-content 0"} display={"flex"} justifyContent={"space-between"} gap={"5px"}>
                            <Box display={"flex"} gap={"4px"}>
                                <Checkbox checked={formData.rememberMe} onChange={(e,val)=>onFormDataChange("rememberMe",val)}/>
                                <Typography variant={"body2"}>Remember Me</Typography>
                            </Box>
                            <Box display={"flex"} gap={"4px"}>
                                <StyledLinkComponent path={"/forgot-password"}>Forgot Password</StyledLinkComponent>
                            </Box>
                        </Box>
                        <Box flex={"fit-content 0"} display={"flex"} gap={"20px"}>
                            <LoadingButton
                                loading={loading} type={"submit"} onClick={onSubmit} size={"large"} sx={{textTransform: 'capitalize', width: "100%"}} color={"primary"}
                                    variant={"contained"} label={"Login"}/>
                        </Box>
                    {/*</Box>*/}
                    </form>
                </Box>
                <CommonRightSidebar/>


            </Box>
        </>
    );
}

export default Index;